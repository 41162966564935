// src/components/Home.tsx
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const motivationalQuotes = [
  "Impossible is just an opinion. — Paulo Coelho",
  "Don't let yesterday take up too much of today. — Will Rogers",
  "You are braver than you believe, stronger than you seem, and smarter than you think.",
  "It always seems impossible until it’s done. — Nelson Mandela",
  "Believe in yourself and all that you are.",
  "Success is not final, failure is not fatal: it is the courage to continue that counts.",
  "You're awesome!",
    "Keep pushing forward.",
    "Today is going to be a great day.",
    "Every day is a new beginning. Take a deep breath, smile, and start again.",
    "You are enough just as you are.",
    "Difficult roads often lead to beautiful destinations.",
    "Happiness is not by chance, but by choice.",
    "The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
    "Be the change that you wish to see in the world. - Mahatma Gandhi",
    "What you get by achieving your goals is not as important as what you become by achieving your goals. - Zig Ziglar",
    "Act as if what you do makes a difference. It does. - William James",
    "Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
    "Believe you can and you're halfway there. - Theodore Roosevelt",
    "You do not find the happy life. You make it. - Camilla Eyring Kimball",
    "The only impossible journey is the one you never begin. - Tony Robbins",
    "The power of imagination makes us infinite. - John Muir",
    "Try to be a rainbow in someone's cloud. - Maya Angelou",
    "You are braver than you believe, stronger than you seem, and smarter than you think. - A.A. Milne",
    "Do what you can with all you have, wherever you are. - Theodore Roosevelt",
    "You get what you give. - Jennifer Lopez",
    "Dream big and dare to fail. - Norman Vaughan",
    "Light tomorrow with today. - Elizabeth Barrett Browning",
    "With the new day comes new strength and new thoughts. - Eleanor Roosevelt",
    "Life is 10% what happens to us and 90% how we react to it. - Charles R. Swindoll",
    "Keep your face always toward the sunshine—and shadows will fall behind you. - Walt Whitman",
    "It’s not whether you get knocked down, it’s whether you get up. - Vince Lombardi",
    "Your passion is waiting for your courage to catch up. - Isabelle Lafleche",
    "Magic is believing in yourself. If you can make that happen, you can make anything happen. - Johann Wolfgang von Goethe",
    "The only difference between ordinary and extraordinary is that little extra. - Jimmy Johnson",
    "The best way to predict your future is to create it. - Abraham Lincoln",
    "You are the artist of your own life. Don’t hand the paintbrush to anyone else.",
    "I am in charge of how I feel and today I am choosing happiness.",
    "Life doesn’t have to be perfect to be wonderful.",
    "Anxiety is just thinking you have to figure out everything all at once. Breathe. You’re strong. You got this. Take it day by day.",
    "Stay close to anything that makes you glad you are alive.",
    "It’s okay to be a glowstick; sometimes we need to break before we shine.",
    "Mistakes are proof that you are trying.",
    "Life is tough, but so are you.",
    "Don’t look back. You’re not going that way.",
    "She remembered who she was and the game changed. - Lalah Deliah",
    "Wherever life plants you, bloom with grace.",
    "Your only limit is your mind.",
    "Nothing is permanent. Don’t stress yourself too much because no matter how bad the situation is, it will change.",
    "You are magic. Don’t ever apologize for the fire in you.",
    "The comeback is always stronger than the setback.",
    "Focus on the step in front of you, not the whole staircase.",
    "I am creating a life of abundance and happiness.",
    "Positivity, confidence, and persistence are key in life, so never give up on yourself.",
    "Breathe. The universe is taking care of everything else.",
    "Choose what makes your heart bloom.",
    "Your potential is endless.",
    "Happiness is an inside job.",
    "Be proud of how hard you are working.",
    "You can be both a masterpiece and a work in progress simultaneously.",
    "Speak to yourself like someone you love.",
    "Embrace the glorious mess that you are. - Elizabeth Gilbert",
    "Do not give your past the power to define your future.",
    "Every moment is a fresh beginning. - T.S. Eliot",
    "Release the need to control the outcome. Trust the process.",
    "The grass is greener where you water it.",
    "I am deserving of the kindness I show others.",
    "I choose to make the rest of my life the best of my life. - Louise Hay",
    "I am in the process of becoming the best version of myself.",
    "Gratitude changes everything.",
    "I am thankful for my struggle because without it, I wouldn’t have stumbled across my strength.",
    "I am open to the magic of new beginnings.",
    "Self-love is not selfish; you cannot truly love another until you know how to love yourself.",
    "Just because you are struggling does not mean you are failing.",
    "Every little thing is gonna be alright. - Bob Marley",
    "Be gentle with yourself, you’re doing the best you can.",
    "Don’t ruin a good today by thinking about a bad yesterday. Let it go.",
    "Heal, so when someone tells you they love you, you allow yourself to believe them.",
    "Growth is growth, no matter how small.",
    "The sun will rise and we will try again.",
    "Strive not to be a success, but rather to be of value. - Albert Einstein",
    "You can’t go back and change the beginning, but you can start where you are and change the ending. - C.S. Lewis",
    "Fall in love with taking care of yourself. Mind. Body. Spirit.",
    "Happiness often sneaks in through a door you didn’t know you left open. - John Barrymore",
    "The purpose of our lives is to be happy. - Dalai Lama",
    "Turn your wounds into wisdom. - Oprah Winfrey",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "I am not what happened to me, I am what I choose to become. - Carl Jung",
    "Dreaming, after all, is a form of planning. - Gloria Steinem",
    "What we think, we become. - Buddha",
    "The mind is everything. What you think you become. - Buddha",
    "Life is not measured by the number of breaths we take, but by the moments that take our breath away. - Maya Angelou",
    "The only person you are destined to become is the person you decide to be. - Ralph Waldo Emerson",
    "Go confidently in the direction of your dreams! Live the life you’ve imagined. - Henry David Thoreau",
    "Life is really simple, but we insist on making it complicated. - Confucius",
    "In order to be irreplaceable one must always be different. - Coco Chanel",
    "What lies behind us and what lies before us are tiny matters compared to what lies within us. - Ralph Waldo Emerson",
    "The best and most beautiful things in the world cannot be seen or even touched - they must be felt with the heart. - Helen Keller",
    "It is during our darkest moments that we must focus to see the light. - Aristotle",
    "Find a place inside where there’s joy, and the joy will burn out the pain. - Joseph Campbell",
    "Don’t count the days, make the days count. - Muhammad Ali",
    "Your task is not to seek for love, but merely to seek and find all the barriers within yourself that you have built against it. - Rumi",
    "The only thing we have to fear is fear itself. - Franklin D. Roosevelt",
    "Do not go where the path may lead, go instead where there is no path and leave a trail. - Ralph Waldo Emerson",
    "The best revenge is massive success. - Frank Sinatra",
    "Life is what happens when you’re busy making other plans. - John Lennon",
    "The greatest glory in living lies not in never falling, but in rising every time we fall. - Nelson Mandela",
    "The way to get started is to quit talking and begin doing. - Walt Disney",
    "Your time is limited, don’t waste it living someone else’s life. - Steve Jobs",
    "Don’t be trapped by dogma – which is living with the results of other people’s thinking. - Steve Jobs",
    "If life were predictable it would cease to be life, and be without flavor. - Eleanor Roosevelt",
    "Life is a succession of lessons which must be lived to be understood. - Ralph Waldo Emerson",
    "When you reach the end of your rope, tie a knot in it and hang on. - Franklin D. Roosevelt",
    "Always remember that you are absolutely unique. Just like everyone else. - Margaret Mead",
    "Don’t judge each day by the harvest you reap but by the seeds that you plant. - Robert Louis Stevenson",
    "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
    "Tell me and I forget. Teach me and I remember. Involve me and I learn. - Benjamin Franklin",
    "The best and most beautiful things in the world cannot be seen or even touched - they must be felt with the heart. - Helen Keller",
    "It is during our darkest moments that we must focus to see the light. - Aristotle",
    "Whoever is happy will make others happy too. - Anne Frank",
    "Do not go where the path may lead, go instead where there is no path and leave a trail. - Ralph Waldo Emerson",
    "You will face many defeats in life, but never let yourself be defeated. - Maya Angelou",
    "The greatest glory in living lies not in never falling, but in rising every time we fall. - Nelson Mandela",
    "In the end, it’s not the years in your life that count. It’s the life in your years. - Abraham Lincoln",
    "Never let the fear of striking out keep you from playing the game. - Babe Ruth",
    "Life is either a daring adventure or nothing at all. - Helen Keller",
    "Many of life’s failures are people who did not realize how close they were to success when they gave up. - Thomas A. Edison",
    "You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose. - Dr. Seuss",
    "Tend to the garden you can touch.",
    "Be so completely yourself that everyone else feels safe to be themselves, too.",
    "Replace fear of the unknown with curiosity.",
    "Life shrinks or expands in proportion to one's courage. - Anais Nin",
    "Everything you’ve ever wanted is on the other side of fear. - George Addair",
    "Dreams don't work unless you do.",
    "Stop being afraid of what could go wrong and start being excited about what could go right.",
    "Your life does not get better by chance, it gets better by change. - Jim Rohn",
    "What if I fall? Oh, but my darling, what if you fly? - Erin Hanson",
    "No one is you and that is your power.",
    "Do more things that make you forget to check your phone.",
    "Be somebody who makes everybody feel like a somebody.",
    "You always pass failure on the way to success. - Mickey Rooney",
    "What you do today can improve all your tomorrows. - Ralph Marston",
    "You don’t have to be great to start, but you have to start to be great. - Zig Ziglar",
    "A positive mindset brings positive things.",
    "Positivity always wins… Always. - Gary Vaynerchuk",
    "Keep looking up… that’s the secret of life. - Snoopy",
    "Once you choose hope, anything’s possible. - Christopher Reeve",
    "You can if you think you can. - George Reeves",
    "Where there is love and inspiration, I don’t think you can go wrong. - Ella Fitzgerald",
    "Limit your 'always' and your 'nevers.' - Amy Poehler",
    "You are never too old to set another goal or to dream a new dream. - C.S. Lewis",
    "Try to be a rainbow in someone else’s cloud. - Maya Angelou",
    "You do not find the happy life. You make it. - Camilla Eyring Kimball",
    "Inspiration comes from within yourself. One has to be positive. When you're positive, good things happen. - Deep Roy",
    "Sometimes you will never know the value of a moment, until it becomes a memory. - Dr. Seuss",
    "The most wasted of days is one without laughter. - E.E. Cummings",
    "You must do the things you think you cannot do. - Eleanor Roosevelt",
    "It isn’t where you came from. It’s where you’re going that counts. - Ella Fitzgerald",
    "Life changes very quickly, in a very positive way, if you let it. - Lindsey Vonn",
    "Keep your face to the sunshine and you cannot see a shadow. - Helen Keller",
    "My mission in life is not merely to survive, but to thrive. - Maya Angelou",
    "You are always one decision away from a totally different life.",
    "Life is like riding a bicycle. To keep your balance, you must keep moving. - Albert Einstein",
    "The most important thing is to enjoy your life—to be happy—it's all that matters. - Audrey Hepburn",
    "I have chosen to be happy because it is good for my health. - Voltaire",
    "To succeed in life, you need three things: a wishbone, a backbone, and a funny bone. - Reba McEntire",
    "Happiness is not something ready-made. It comes from your own actions. - Dalai Lama",
    "Stay positive, work hard, make it happen.",
    "Success is not how high you have climbed, but how you make a positive difference to the world. - Roy T. Bennett",
    "Every day may not be good... but there’s something good in every day. - Alice Morse Earle",
    "Positive thinking will let you do everything better than negative thinking will. - Zig Ziglar",
    "Keep your face always toward the sunshine—and shadows will fall behind you. - Walt Whitman",
    "Find something positive in every day, even if some days you have to look a little harder.",
    "The only time you fail is when you fall down and stay down. - Stephen Richards",
    "When you can’t find the sunshine, be the sunshine.",
    "The best way to predict the future is to invent it. - Alan Kay",
    "You are capable of amazing things.",
    "Be the reason someone believes in the goodness of people.",
    "Every day is a second chance.",
    "It’s a good day to have a good day.",
    "Your limitation—it’s only your imagination.",
    "Push yourself, because no one else is going to do it for you.",
    "Great things never come from comfort zones.",
    "Dream it. Wish it. Do it.",
    "Success doesn’t just find you. You have to go out and get it.",
    "The harder you work for something, the greater you’ll feel when you achieve it.",
    "Dream bigger. Do bigger.",
    "Don’t stop when you’re tired. Stop when you’re done.",
    "Wake up with determination. Go to bed with satisfaction.",
    "Do something today that your future self will thank you for.",
    "Little things make big days.",
    "It’s going to be hard, but hard does not mean impossible.",
    "Don’t wait for opportunity. Create it.",
    "Sometimes we’re tested not to show our weaknesses, but to discover our strengths.",
    "The key to success is to focus on goals, not obstacles.",
    "Dream it. Believe it. Build it.",
    "Motivation is what gets you started. Habit is what keeps you going. - Jim Ryun",
    "Setting goals is the first step in turning the invisible into the visible. - Tony Robbins",
    "All our dreams can come true if we have the courage to pursue them. - Walt Disney",
    "The only way to achieve the impossible is to believe it is possible. - Charles Kingsleigh",
    "Only the paranoid survive. - Andy Grove",
    "It’s hard to beat a person who never gives up. - Babe Ruth",
    "I wake up every morning and think to myself, ‘how far can I push this company in the next 24 hours.’ - Leah Busque",
    "If people are doubting how far you can go, go so far that you can’t hear them anymore. - Michele Ruiz",
    "We need to accept that we won’t always make the right decisions, that we’ll screw up royally sometimes – understanding that failure is not the opposite of success, it’s part of success. - Arianna Huffington",
    "Write it. Shoot it. Publish it. Crochet it, sauté it, whatever. MAKE. - Joss Whedon",
    "Everything you can imagine is real. - Pablo Picasso",
    "Do one thing every day that scares you. - Eleanor Roosevelt",
    "You’ve gotta dance like there’s nobody watching, love like you’ll never be hurt, sing like there’s nobody listening, and live like it’s heaven on earth. - William W. Purkey",
    "Fairy tales are more than true: not because they tell us that dragons exist, but because they tell us that dragons can be beaten. - Neil Gaiman",
    "Everything you’ve ever wanted is on the other side of fear. — George Addair",
    "Do what you feel in your heart to be right – for you’ll be criticized anyway.— Eleanor Roosevelt",
    "Happiness is not something readymade. It comes from your own actions. — Dalai Lama XIV",
    "Whatever you are, be a good one. — Abraham Lincoln",
    "The same boiling water that softens the potato hardens the egg. It’s what you’re made of. Not the circumstances. — Unknown",
    "If we have the attitude that it’s going to be a great day it usually is. — Catherine Pulsifier",
    "You can either experience the pain of discipline or the pain of regret. The choice is yours. — Unknown",
    "Impossible is just an opinion. — Paulo Coelho",
    "Your passion is waiting for your courage to catch up. — Isabelle Lafleche",
    "Magic is believing in yourself. If you can make that happen, you can make anything happen. — Johann Wolfgang Von Goethe",
    "If something is important enough, even if the odds are stacked against you, you should still do it. — Elon Musk",
    "Hold the vision, trust the process. — Unknown",
    "Don’t be afraid to give up the good to go for the great. — John D. Rockefeller",
    "People who wonder if the glass is half empty or full miss the point. The glass is refillable. — Unknown",
    "Just another Magic Monday — Unknown",
    "One day or day one. You decide. — Unknown",
    "It’s Monday… time to motivate and make dreams and goals happen. Let’s go! — Heather Stillufsen",
    "It was a Monday and they walked on a tightrope to the sun.― Marcus Zusak",
    "Goodbye blue Monday. ― Kurt Vonnegut",
    "So. Monday. We meet again. We will never be friends—but maybe we can move past our mutual enmity toward a more-positive partnership. — Julio Alexi Genao",
    "When life gives you Monday, dip it in glitter and sparkle all day. — Ella Woodword",
    "All Motivation Mondays need are a little more coffee and a lot more mascara. — Unknown",
    "I’m alive, motivated, and ready to slay the day #MONSLAY — Unknown",
    "New Monday. New week. New goals. — Unknown",
    "Mondays are for fresh starts. — Unknown",
    "The thing about today is… It’s Monday! No other day could get the week off to such a great start. — Anthony T. Hincks",
    "Attack Monday with enthusiasm, laughter, thousands of smiles, and cheerfulness. — Unknown",
    "Let today be the start of something new. — Unknown",
    "Monday won’t be so gloomy if you believe that something good is always bound to happen. — Unknown",
    "Every Monday is a chance to start a new life, so use it. — Unknown",
    "Forget about the traffic, stressful work, and business meetings today, be happy that the sun is shining and stay positive. — Unknown",
    "If Monday is strong coffee, don’t despair and add a few pieces of sugar to make it tasty and sweet! — Unknown",
    "Mondays are the start of the workweek which offer new beginnings 52 times a year!— David Dweck",
    "Your Monday morning thoughts set the tone for your whole week. See yourself getting stronger, and living a fulfilling, happier & healthier life. — Germany Kent",
    "You are in control. Never allow your Monday to be manic. — Andrea L’Artiste",
    "Monday is a good day to have a great day! It’s all in your attitude. — Unknown",
    "I must break you. — Rocky IV",
    "Try not. Do, or do not. There is no try. — Yoda",
    "Life moves pretty fast. If you don’t stop and look around once in a while, you could miss it. — Ferris Bueller",
    "To infinity and beyond! — Buzz Lightyear",
    "You talking to me? — Taxi Driver",
    "There’s no crying in baseball! — A League of Their Own",
    "Magic Mirror on the wall, who is the fairest one of all? — Snow White and the Seven Dwarfs",
    "Keep your friends close, but your enemies closer. — The Godfather Part II",
    "I’ll be back. — The Terminator",
    "You can’t handle the truth! — A Few Good Men",
    "I feel the need—the need for speed. — Top Gun",
    "It’s alive! It’s alive! — Frankenstein",
    "Houston, we have a problem. — Apollo 13",
    "There’s no place like home. — The Wizard of Oz",
    "I’m the king of the world! — Titanic",
    "Carpe diem. Seize the day, boys. Make your lives extraordinary. — Dead Poets Society",
    "Elementary, my dear Watson. — The Adventures of Sherlock Holmes",
    "It’s not the years, honey. It’s the mileage. — Raiders of the Lost Ark",
    "My mama always said life was like a box of chocolates. You never know what you’re gonna get. — Forrest Gump",
    "May the Force be with you. — Star Wars",
    "There’s no place like home. — The Wizard of Oz",
    "I think this is the beginning of a beautiful friendship. — Casablanca",
    "There’s no crying in baseball! — A League of Their Own",
    "Life is a banquet, and most poor suckers are starving to death! — Auntie Mame",
    "Keep your friends close, but your enemies closer. — The Godfather Part II",
    "As God is my witness, I’ll never be hungry again. — Gone With the Wind",
    "Well, nobody’s perfect. — Some Like It Hot",
    "It’s alive! It’s alive! — Frankenstein",
    "Houston, we have a problem. — Apollo 13",
    "You had me at ‘hello.’ — Jerry Maguire",
    "Just keep swimming. — Finding Nemo",
    "Today, I consider myself the luckiest man on the face of the earth. — The Pride of the Yankees",
    "You is kind. You is smart. You is important. — The Help",
    "I have always depended on the kindness of strangers. — A Streetcar Named Desire",
    "Hasta la vista, baby. — Terminator 2: Judgment Day",
    "So it goes. — Kurt Vonnegut",
    "The only way out of the labyrinth of suffering is to forgive. — John Green",
    "Hello, old friend. — Death",
    "And so it goes... — Billy Pilgrim",
    "Life would be tragic if it weren’t funny. — Stephen Hawking",
    "Live in the sunshine, swim the sea, drink the wild air. — Ralph Waldo Emerson",
    "The only people who ever get anyplace interesting are the people who get lost. — Henry David Thoreau",
    "To live is the rarest thing in the world. Most people exist, that is all. — Oscar Wilde",
    "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment. — Ralph Waldo Emerson",
    "In three words I can sum up everything I’ve learned about life: It goes on. — Robert Frost",
    "Life is what happens to us while we are making other plans. — Allen Saunders",
    "The unexamined life is not worth living. — Socrates",
    "The purpose of our lives is to be happy. — Dalai Lama",
    "Life is really simple, but men insist on making it complicated. — Confucius",
    "Life is a journey that must be traveled no matter how bad the roads and accommodations. — Oliver Goldsmith",
    "We do not remember days, we remember moments. — Cesare Pavese",
    "Life is short, and it is up to you to make it sweet. — Sarah Louise Delany",
    "Good friends, good books, and a sleepy conscience: this is the ideal life. — Mark Twain",
    "Most great people have attained their greatest success just one step beyond their greatest failure. — Napoleon Hill",
    "Opportunities don't happen, you create them. — Chris Grosser",
    "Love your family, work super hard, live your passion. — Gary Vaynerchuk",
    "It is never too late to be what you might have been. — George Eliot",
    "You have to learn the rules of the game. And then you have to play better than anyone else. — Albert Einstein",
    "Every child is an artist. The problem is how to remain an artist once he grows up. — Pablo Picasso",
    "The starting point of all achievement is desire. — Napoleon Hill",
    "Success is walking from failure to failure with no loss of enthusiasm. — Winston Churchill",
    "All progress takes place outside the comfort zone. — Michael John Bobak",
    "Don't be afraid to give up the good to go for the great. — John D. Rockefeller",
    "I find that the harder I work, the more luck I seem to have. — Thomas Jefferson",
    "The road to success and the road to failure are almost exactly the same. — Colin R. Davis",
    "Success usually comes to those who are too busy to be looking for it. — Henry David Thoreau",
    "Opportunities don't happen. You create them. — Chris Grosser",
    "Don't be afraid to give up the good to go for the great. — John D. Rockefeller",
    "I find that the harder I work, the more luck I seem to have. — Thomas Jefferson",
    "There are two types of people who will tell you that you cannot make a difference in this world: those who are afraid to try and those who are afraid you will succeed. — Ray Goforth",
    "Successful people do what unsuccessful people are not willing to do. Don't wish it were easier; wish you were better. — Jim Rohn",
    "Try not to become a man of success. Rather become a man of value. — Albert Einstein",
    "Never give in except to convictions of honor and good sense. — Winston Churchill",
    "Stop chasing the money and start chasing the passion. — Tony Hsieh",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. — Albert Schweitzer",
    "Success isn’t just about what you accomplish in your life; it’s about what you inspire others to do. — Unknown",
    "Fall seven times and stand up eight. — Japanese Proverb",
    "Some people dream of success while others wake up and work. — Unknown",
    "If you can dream it, you can do it. — Walt Disney",
    "The difference between who you are and who you want to be is what you do. — Unknown",
    "A successful man is one who can lay a firm foundation with the bricks that other throw at him. — David Brinkley",
    "In order to succeed, your desire for success should be greater than your fear of failure. — Bill Cosby",
    "In order to succeed, we must first believe that we can. — Nikos Kazantzakis",
    "The only place where success comes before work is in the dictionary. — Vidal Sassoon",
    "You don’t have to be great to start, but you have to start to be great. — Zig Ziglar",
    "Aim for the moon. If you miss, you may hit a star. — W. Clement Stone",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. — Herman Cain",
    "The secret of success is to do the common thing uncommonly well. — John D. Rockefeller Jr.",
    "I never dreamed about success. I worked for it. — Estee Lauder",
    "The only limit to our realization of tomorrow will be our doubts of today. — Franklin D. Roosevelt",
    "It is hard to fail, but it is worse never to have tried to succeed. — Theodore Roosevelt",
    "I was taught that the way of progress was neither swift nor easy. — Marie Curie",
    "Only those who dare to fail greatly can ever achieve greatly. — Robert F. Kennedy",
    "Failure is another steppingstone to greatness. — Oprah Winfrey",
    "The road to success and the road to failure are almost exactly the same. — Colin R. Davis",
    "Success is not how high you have climbed, but how you make a positive difference to the world. — Roy T. Bennett",
    "Success is going from failure to failure without losing your enthusiasm. — Winston Churchill",
    "Success isn’t about how much money you make; it’s about the difference you make in people’s lives. — Michelle Obama",
    "Be not afraid of life. Believe that life is worth living, and your belief will help create the fact. — William James",
    "We grow through what we go through.",
    "Stay patient and trust your journey.",
    "Difficult roads often lead to beautiful destinations.",
    "Believe in yourself a little more.",
    "Happiness is letting go of what you think your life is supposed to look like.",
    "Gratitude turns what we have into enough.",
    "You are stronger than you know.",
    "Overcoming them is what makes life meaningful.",
    "It’s okay to be a work in progress.",
    "Small steps in the right direction can turn out to be the biggest step of your life.",
    "Healing is not linear.",
    "Be where you are, not where you think you should be.",
    "Your speed doesn’t matter, forward is forward.",
    "One day at a time.",
];

const getRandomQuote = (currentQuote: string) => {
  let newQuote = currentQuote;
  while (newQuote === currentQuote) {
    newQuote = motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];
  }
  return newQuote;
};

const Home = () => {
  const [currentQuote, setCurrentQuote] = useState(motivationalQuotes[0]);
  const [streak, setStreak] = useState(1);
  const [pawPrints, setPawPrints] = useState(0);
  const [petName, setPetName] = useState('');
  const [petImage, setPetImage] = useState('');
  const navigate = useNavigate();

  // Fetch pet name and avatar from Firestore
  useEffect(() => {
    const fetchPetData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setPetName(userData.petName || '');
          setPetImage(userData.petImagePath || '/assets/pet1.jpeg');
          setStreak(userData.streak || 1);  // Assuming you're tracking streak in Firestore
          setPawPrints(userData.pawPrints || 0);
        }
      }
    };

    fetchPetData();
  }, []);

  // Navigation Handlers
  const goToChat = () => navigate('/chat');
  const goToSOS = () => navigate('/sos');
  const goToSignOut = () => navigate('/login');
  const goToJournal = () => navigate('/journal');
  const GoToHome = () => navigate('/home');
  const goToSelectAvatar = () => navigate('/select-avatar');
  const goToMoodTracker = () => navigate('/mood');  // Mood Tracker Navigation

  // Handle Randomized Quote
  const handleQuoteChange = () => {
    setCurrentQuote(getRandomQuote(currentQuote));
  };

  return (
    <div className="flex flex-col items-center justify-between h-screen bg-gray-100">
      {/* Top Section: Mood, Streak, Paw Prints, Talk to Me, and SOS */}
      <div className="w-full p-4 flex justify-between items-center bg-white shadow-md">
        {/* Mood Button + Streak + Paw Prints */}
        <div className="flex items-center space-x-4">
          <button
            onClick={goToMoodTracker}
            className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            + Mood
          </button>
          <span className="text-red-500 text-lg">🔥 {streak}</span>
          <span className="text-blue-500 text-lg">🐾 {pawPrints}</span>
        </div>

        {/* Talk to Me and SOS Buttons */}
        <div className="flex space-x-2">
          <button
            onClick={goToChat}
            className="bg-cyan-500 text-white py-2 px-4 rounded-lg hover:bg-cyan-600"
          >
            Talk to me!
          </button>
          <button
            onClick={goToSOS}
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
          >
            SOS
          </button>
        </div>
      </div>

      {/* Words of Affirmation: Scrollable Quotes */}
      <div className="mt-4 w-4/5 bg-gray-200 p-4 rounded-lg shadow-md text-center">
        <div
          onClick={handleQuoteChange}
          className="cursor-pointer inline-block mr-4"
        >
          ◀
        </div>
        <p className="inline-block italic text-gray-700 transition-all ease-in-out duration-300">
          "{currentQuote}"
        </p>
        <div
          onClick={handleQuoteChange}
          className="cursor-pointer inline-block ml-4"
        >
          ▶
        </div>
      </div>

      {/* Pet Name */}
      <h2 className="text-xl font-bold mt-4 text-center">Hi! I'm {petName}</h2>

      {/* Pet Image */}
      <div className="text-center mt-4">
        <img src={petImage} alt="Your Pet" className="h-40 w-40 rounded-full mb-4 shadow-lg" />
      </div>

      {/* Bottom Navigation Bar */}
      <div className="w-full bg-white p-4 shadow-md flex justify-between">
        {/* Self-Care */}
        <button onClick={GoToHome} className="text-gray-600 hover:text-blue-400 flex flex-col items-center">
          <div className="h-8 w-8">🏠</div>
          <span>Home</span>
        </button>

        {/* Journal */}
        <button onClick={goToJournal} className="text-gray-600 hover:text-blue-400 flex flex-col items-center">
          <div className="h-8 w-8">📔</div>
          <span>Journal</span>
        </button>

        {/* Profile */}
        <button onClick={goToSignOut} className="text-gray-600 hover:text-red-400 flex flex-col items-center">
          <div className="h-8 w-8">👤</div>
          <span>Sign Out</span>
        </button>
      </div>

      {/* Paw Button to Change Pet */}
      <button
        onClick={goToSelectAvatar}
        className="fixed bottom-24 right-8 bg-cyan-500 text-white p-4 rounded-full shadow-lg hover:bg-cyan-600"
      >
        🐾
      </button>
    </div>
  );
};

export default Home;
