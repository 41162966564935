// src/components/JournalDetail.tsx
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const JournalDetail = () => {
  const { id } = useParams();  // Get the journal ID from the URL
  const [journal, setJournal] = useState<any>(null);  // State to store the journal entry
  const [error, setError] = useState('');  // State to handle errors
  const [isEditMode, setIsEditMode] = useState(false);  // Track edit mode
  const [editedText, setEditedText] = useState('');  // Store the edited text
  const [editedPrompt, setEditedPrompt] = useState('');  // Store the edited prompt
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJournal = async () => {
      const user = auth.currentUser;

      // Check if user and id are valid
      if (!user || !id) {
        setError('Invalid user or journal ID.');
        navigate('/');  // Redirect to home if invalid
        return;
      }

      try {
        const journalRef = doc(db, 'users', user.uid, 'journals', id);
        const journalSnap = await getDoc(journalRef);

        if (journalSnap.exists()) {
          const journalData = journalSnap.data();
          setJournal(journalData);
          setEditedText(journalData.journalText);  // Initialize the edit text
          setEditedPrompt(journalData.prompt);     // Initialize the edit prompt
        } else {
          setError('Journal entry not found.');
        }
      } catch (err) {
        setError('Failed to fetch the journal entry. Please try again.');
      }
    };

    fetchJournal();
  }, [id, navigate]);

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // Handle saving the edited journal entry
  const handleSave = async () => {
    const user = auth.currentUser;
    if (!user || !id) {
      setError('Invalid user or journal ID.');
      return;
    }

    try {
      const journalRef = doc(db, 'users', user.uid, 'journals', id);
      await updateDoc(journalRef, {
        journalText: editedText,
        prompt: editedPrompt
      });
      setJournal({ ...journal, journalText: editedText, prompt: editedPrompt });
      setIsEditMode(false);  // Exit edit mode after saving
    } catch (err) {
      setError('Failed to save the journal entry. Please try again.');
    }
  };

  // Handle cancel editing and revert changes
  const handleCancel = () => {
    setEditedText(journal.journalText);  // Revert to original text
    setEditedPrompt(journal.prompt);     // Revert to original prompt
    setIsEditMode(false);  // Exit edit mode
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-pastel-light">
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : journal ? (
        <div className="w-full max-w-md p-6 bg-white shadow-lg rounded-lg">
          {isEditMode ? (
            <>
              <input
                type="text"
                value={editedPrompt}
                onChange={(e) => setEditedPrompt(e.target.value)}
                className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
                placeholder="Edit your journal prompt"
              />
              <textarea
                rows={6}
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Edit your journal entry"
              />
              <div className="flex justify-between mt-4">
                <button
                  onClick={handleSave}
                  className="bg-green-400 text-white py-2 px-4 rounded-lg hover:bg-green-500"
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="bg-gray-300 text-white py-2 px-4 rounded-lg hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-2xl font-bold mb-4">{journal.prompt || 'Journal Entry'}</h2>
              <p className="mb-6 text-gray-600">{new Date(journal.date.seconds * 1000).toLocaleDateString()}</p>
              <p className="text-gray-700 mb-4">{journal.journalText}</p>
              <div className="flex justify-between mt-4">
                <button
                  onClick={toggleEditMode}
                  className="bg-blue-400 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
                >
                  Edit
                </button>
                <button
                  onClick={() => navigate('/journal')}
                  className="bg-gray-300 text-white py-2 px-4 rounded-lg hover:bg-gray-400"
                >
                  Back to Journal Dashboard
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default JournalDetail;
