// src/components/Register.tsx
import { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError('Registration failed. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <div className="flex justify-center mb-4">
          <span className="text-5xl text-gray-800">&#128394;</span>
        </div>
        <h2 className="text-center text-gray-600 mb-4">Create your account</h2>

        <form onSubmit={handleRegister}>
          <input
            type="email"
            placeholder="Email"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-lg hover:bg-gray-800 transition duration-200"
          >
            Register
          </button>
        </form>

        <div className="flex items-center justify-between mt-6">
          <hr className="w-1/3 border-gray-300" />
          <p className="text-sm text-gray-500">Or continue with</p>
          <hr className="w-1/3 border-gray-300" />
        </div>

        <div className="flex justify-around mt-4">
          <button>
            <img src="/assets/google.png" alt="Google" className="h-12 w-12" />
          </button>
          


        </div>

        <div className="text-center mt-6">
          <p className="text-sm text-gray-500">
            Already have an account? <a href="/login" className="text-blue-500 hover:underline">Log in</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;

/*
<button>
            <img src="/assets/apple.png" alt="Apple" className="h-12 w-12" />
          </button>
          <button>
            <img src="/assets/facebook.png" alt="Facebook" className="h-12 w-12" />
          </button>
          <button>
            <img src="/assets/microsoft.png" alt="Microsoft" className="h-12 w-12" />
          </button>
*/