// src/components/MoodHistory.tsx
import { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const MoodHistory = () => {
  const [moods, setMoods] = useState<any[]>([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);  // Toggle delete mode
  const [selectedMoods, setSelectedMoods] = useState<string[]>([]);  // Track selected moods for deletion
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMoods = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/');
        return;
      }

      const moodsRef = collection(db, 'users', user.uid, 'moods');
      const q = query(moodsRef, orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);

      const moodEntries: any[] = [];
      querySnapshot.forEach((doc) => {
        moodEntries.push({ id: doc.id, ...doc.data() });
      });

      setMoods(moodEntries);
    };

    fetchMoods();
  }, [navigate]);

  // Handle selecting mood for deletion
  const toggleSelectMood = (id: string) => {
    setSelectedMoods(selectedMoods.includes(id)
      ? selectedMoods.filter(moodId => moodId !== id)
      : [...selectedMoods, id]
    );
  };

  // Handle deleting selected moods
  const handleDeleteSelected = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      for (let id of selectedMoods) {
        const moodRef = doc(db, 'users', user.uid, 'moods', id);
        await deleteDoc(moodRef);
      }
      setMoods(moods.filter(mood => !selectedMoods.includes(mood.id)));
      setSelectedMoods([]);  // Clear selected moods after deletion
      setIsDeleteMode(false);  // Exit delete mode
    } catch (err) {
      console.error('Failed to delete moods:', err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-pastel-light">
      <h2 className="text-3xl font-bold text-gray-700 mb-4">Your Mood History</h2>
      <button
        onClick={() => setIsDeleteMode(!isDeleteMode)}
        className="bg-red-400 text-white py-2 px-4 rounded-lg mb-4 hover:bg-red-500 transition duration-200"
      >
        {isDeleteMode ? 'Cancel Delete Mode' : 'Enter Delete Mode'}
      </button>

      {isDeleteMode && selectedMoods.length > 0 && (
        <button
          onClick={handleDeleteSelected}
          className="bg-red-400 text-white py-2 px-4 rounded-lg mb-4 hover:bg-red-500 transition duration-200"
        >
          Delete Selected Moods
        </button>
      )}

      <div className="w-full max-w-md">
        {moods.length === 0 ? (
          <p className="text-gray-600 text-center">No moods logged yet.</p>
        ) : (
          moods.map((mood) => (
            <div key={mood.id} className={`border-b py-4 ${isDeleteMode && selectedMoods.includes(mood.id) ? 'bg-red-100' : ''}`}>
              <p className="text-sm text-gray-500">
                {new Date(mood.date.seconds * 1000).toLocaleDateString()} - {mood.title}
              </p>
              <p className="text-gray-700">Mood: {mood.mood} ({mood.moodValue}/5)</p>
              <p className="text-gray-600">{mood.description.substring(0, 100)}...</p>
              <div className="flex justify-between mt-2">
                <button
                  onClick={() => navigate(`/mood/${mood.id}`)}
                  className="text-blue-400 hover:underline"
                >
                  Read more
                </button>
                {isDeleteMode && (
                  <input
                    type="checkbox"
                    checked={selectedMoods.includes(mood.id)}
                    onChange={() => toggleSelectMood(mood.id)}
                  />
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MoodHistory;
