// src/components/JournalDashboard.tsx
import { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const JournalDashboard = () => {
  const [journals, setJournals] = useState<any[]>([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);  // State for delete mode
  const [selectedJournals, setSelectedJournals] = useState<any[]>([]);  // State for selected journals to delete
  const navigate = useNavigate();

  // Fetch journal entries from Firestore
  useEffect(() => {
    const fetchJournals = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/');
        return;
      }

      const journalsRef = collection(db, 'users', user.uid, 'journals');
      const q = query(journalsRef, orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);

      const journalEntries: any[] = [];
      querySnapshot.forEach((doc) => {
        journalEntries.push({ id: doc.id, ...doc.data() });
      });

      setJournals(journalEntries);
    };

    fetchJournals();
  }, [navigate]);

  // Handle selecting journals to delete
  const handleSelectJournal = (journalId: string) => {
    if (selectedJournals.includes(journalId)) {
      setSelectedJournals(selectedJournals.filter(id => id !== journalId));
    } else {
      setSelectedJournals([...selectedJournals, journalId]);
    }
  };

  // Handle deleting selected journals
  const handleDeleteSelected = async () => {
    const user = auth.currentUser;
    if (!user) return;

    for (const journalId of selectedJournals) {
      const journalRef = doc(db, 'users', user.uid, 'journals', journalId);
      await deleteDoc(journalRef);
    }

    // Refresh the journal list
    setJournals(journals.filter(journal => !selectedJournals.includes(journal.id)));
    setSelectedJournals([]);  // Clear the selection
    setIsDeleteMode(false);    // Exit delete mode
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-pastel-light">
      <h2 className="text-3xl font-bold text-gray-700 mb-4">Your Journal Entries</h2>
      <button
        onClick={() => navigate('/new-journal')}
        className="bg-blue-400 text-white py-2 px-4 rounded-lg mb-4 hover:bg-blue-500 transition duration-200"
      >
        Add New Journal Entry
      </button>

      <button
        onClick={() => setIsDeleteMode(!isDeleteMode)}  // Toggle delete mode
        className="bg-red-400 text-white py-2 px-4 rounded-lg mb-4 hover:bg-red-500 transition duration-200"
      >
        {isDeleteMode ? 'Exit Delete Mode' : 'Delete Journals'}
      </button>

      {isDeleteMode && selectedJournals.length > 0 && (
        <button
          onClick={handleDeleteSelected}
          className="bg-red-600 text-white py-2 px-4 rounded-lg mb-4 hover:bg-red-700 transition duration-200"
        >
          Delete Selected ({selectedJournals.length})
        </button>
      )}

      <div className="w-full max-w-md">
        {journals.length === 0 ? (
          <p className="text-gray-600 text-center">No journal entries yet.</p>
        ) : (
          journals.map((journal) => (
            <div key={journal.id} className="border-b py-4">
              <p className="text-sm text-gray-500">
                {new Date(journal.date.seconds * 1000).toLocaleDateString()} - {journal.prompt}
              </p>
              <p className="text-gray-700">{journal.journalText.substring(0, 100)}...</p>

              {isDeleteMode ? (
                <button
                  onClick={() => handleSelectJournal(journal.id)}  // Select for deletion
                  className={`text-red-400 hover:underline mt-2 ${selectedJournals.includes(journal.id) ? 'font-bold' : ''}`}
                >
                  {selectedJournals.includes(journal.id) ? 'Deselect' : 'Select'}
                </button>
              ) : (
                <button
                  onClick={() => navigate(`/journal/${journal.id}`)}  // Navigate to view full entry
                  className="text-blue-400 hover:underline mt-2"
                >
                  Read more
                </button>
              )}
              
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default JournalDashboard;
