// src/components/MoodTracker.tsx
import { useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc, collection, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const moodEmojis = [
  { emoji: "😔", value: 1, description: "Very Sad", backgroundColor: "#000000" }, // Black
  { emoji: "😡", value: 2, description: "Angry", backgroundColor: "#FF0000" }, // Red
  { emoji: "🙁", value: 3, description: "Unhappy", backgroundColor: "#808080" }, // Grey
  { emoji: "😐", value: 4, description: "Neutral", backgroundColor: "#BBE4FF" }, // Light Blue
  { emoji: "😁", value: 5, description: "Very Happy", backgroundColor: "#FFFF00" }, // Yellow
  { emoji: "😍", value: 6, description: "Extremely Happy", backgroundColor: "#FF69B4" }, // Pink
];

const triggers = [
  "Work", "Family", "Health", "Finances", "Social Life", "Self-Care", "Exercise", "Hobbies", "Weather", "Sleep", 
  "Relationships", "Stress", "Diet", "Creativity", "Other"
];

const MoodTracker = () => {
  const [mood, setMood] = useState(moodEmojis[3]);  // Default to neutral
  const [title, setTitle] = useState('');
  const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle trigger selection
  const toggleTrigger = (trigger: string) => {
    if (selectedTriggers.includes(trigger)) {
      setSelectedTriggers(selectedTriggers.filter((t) => t !== trigger));
    } else {
      setSelectedTriggers([...selectedTriggers, trigger]);
    }
  };

  // Handle saving mood entry to Firestore
  const handleSaveMood = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('You need to be logged in to log your mood.');
      return;
    }

    if (!title) {
      setError('Please enter a title for the day.');
      return;
    }

    const moodRef = doc(collection(db, 'users', user.uid, 'moods'));
    await setDoc(moodRef, {
      mood: mood.description,
      moodValue: mood.value,
      title,
      triggers: selectedTriggers,
      description,
      date: Timestamp.now(),
    });

    navigate('/mood-history');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen"
         style={{ backgroundColor: mood.backgroundColor }}> {/* Background changes based on selected emoji */}
      <h2 className="text-3xl font-bold text-gray-700 mb-4">Track Your Mood</h2>
      
      {/* Mood Emojis */}
      <div className="flex justify-between w-3/4 mb-4">
        {moodEmojis.map((m) => (
          <button
            key={m.value}
            onClick={() => setMood(m)}
            className={`text-3xl ${mood.value === m.value ? 'text-blue-400' : 'text-gray-500'} hover:text-blue-500 transition duration-200`}
          >
            {m.emoji}
          </button>
        ))}
      </div>
      <p className="italic text-gray-600 mb-6">Selected Mood: {mood.description}</p>
      
      {/* Title Input */}
      <input
        type="text"
        placeholder="Title for the day"
        className="w-full max-w-md p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-4"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />

      {/* Triggers */}
      <div className="mb-4">
        <p className="text-gray-700 mb-2">What affected your mood today?</p>
        <div className="grid grid-cols-3 gap-2">
          {triggers.map((trigger) => (
            <button
              key={trigger}
              onClick={() => toggleTrigger(trigger)}
              className={`py-2 px-4 rounded-lg ${selectedTriggers.includes(trigger) ? 'bg-blue-400 text-white' : 'bg-gray-300 text-gray-700'} hover:bg-blue-500 transition duration-200`}
            >
              {trigger}
            </button>
          ))}
        </div>
      </div>

      {/* Description */}
      <textarea
        rows={4}
        placeholder="Short description of your day"
        className="w-full max-w-md p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-4"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      {/* Save Button */}
      <button
        onClick={handleSaveMood}
        className="bg-green-400 text-white py-2 px-4 rounded-lg hover:bg-green-500 transition duration-200"
      >
        Save Mood
      </button>
    </div>
  );
};

export default MoodTracker;
