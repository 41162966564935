// src/components/Helplines.tsx
import { useNavigate } from 'react-router-dom';

const Helplines = () => {
  const navigate = useNavigate();

  // Return to the SOS page
  const returnToSOS = () => {
    navigate('/sos');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white text-black p-8">
      <h1 className="text-3xl font-bold text-center mb-6">Helplines & Resources</h1>

      {/* List of Helplines */}
      <ul className="text-lg mb-8">
        <li className="mb-4">
          <strong>National Suicide Prevention Lifeline:</strong>{' '}
          <a href="tel:1-800-273-8255" className="text-blue-500 hover:underline">
            1-800-273-8255
          </a>{' '}
          (or{' '}
          <a href="https://suicidepreventionlifeline.org/chat/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            chat online
          </a>)
        </li>

        <li className="mb-4">
          <strong>Crisis Text Line:</strong>{' '}
          <a href="sms:741741" className="text-blue-500 hover:underline">
            Text HOME to 741741
          </a>{' '}
          (available 24/7)
        </li>

        <li className="mb-4">
          <strong>Domestic Violence Hotline:</strong>{' '}
          <a href="tel:1-800-799-7233" className="text-blue-500 hover:underline">
            1-800-799-7233
          </a>{' '}
          (or{' '}
          <a href="https://www.thehotline.org/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            chat online
          </a>)
        </li>

        <li className="mb-4">
          <strong>Substance Abuse and Mental Health Services (SAMHSA) Helpline:</strong>{' '}
          <a href="tel:1-800-662-4357" className="text-blue-500 hover:underline">
            1-800-662-HELP (4357)
          </a>{' '}
          (24/7 treatment referral and information)
        </li>

        <li className="mb-4">
          <strong>National Eating Disorders Helpline:</strong>{' '}
          <a href="tel:1-800-931-2237" className="text-blue-500 hover:underline">
            1-800-931-2237
          </a>{' '}
          (or{' '}
          <a href="https://www.nationaleatingdisorders.org/help-support/contact-helpline" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            chat online
          </a>)
        </li>

        <li className="mb-4">
          <strong>LGBTQ+ National Help Center:</strong>{' '}
          <a href="tel:1-888-843-4564" className="text-blue-500 hover:underline">
            1-888-843-4564
          </a>{' '}
          (or{' '}
          <a href="https://www.glbthotline.org/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            visit the website
          </a>)
        </li>

        <li className="mb-4">
          <strong>Veterans Crisis Line:</strong>{' '}
          <a href="tel:1-800-273-8255" className="text-blue-500 hover:underline">
            1-800-273-8255
          </a>{' '}
          (Press 1 or{' '}
          <a href="https://www.veteranscrisisline.net/get-help/chat" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            chat online
          </a>)
        </li>

        <li className="mb-4">
          <strong>Childhelp National Child Abuse Hotline:</strong>{' '}
          <a href="tel:1-800-422-4453" className="text-blue-500 hover:underline">
            1-800-4-A-CHILD (422-4453)
          </a>{' '}
          (available 24/7)
        </li>

        <li className="mb-4">
          <strong>Emergency Services:</strong>{' '}
          <a href="tel:911" className="text-blue-500 hover:underline">
            911
          </a>{' '}
          (immediate assistance)
        </li>
      </ul>

      {/* Return to SOS Page Button */}
      <button
        onClick={returnToSOS}
        className="bg-gray-600 text-white py-2 px-6 rounded-full hover:bg-gray-700 transition duration-300"
      >
        Back to SOS
      </button>
    </div>
  );
};

export default Helplines;
