// src/App.tsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import Login from './components/Login';
import SelectAvatar from './components/SelectAvatar';
import Home from './components/Home';
import JournalDashboard from './components/JournalDashboard';
import JournalEntry from './components/JournalEntry';
import MoodTracker from './components/MoodTracker';
import MoodHistory from './components/MoodHistory';
import Chat from './components/Chat';  // Import Chat component
import SOS from './components/SOS';
import Helplines from './components/Helplines';
import JournalDetail from './components/JournalDetail';
import Register from './components/Register';

function App() {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);  // Track auth state

  // Set persistence and check if the user is logged in
  useEffect(() => {
    // Set persistence to local so users stay logged in
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Check if the user is authenticated
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setIsAuth(true);  // User is authenticated
          } else {
            setIsAuth(false);  // User is not authenticated
          }
        });
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
        setIsAuth(false);
      });
  }, []);

  if (isAuth === null) {
    return <div>Loading...</div>; // Show a loading state while checking auth
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={isAuth ? <Navigate to="/home" /> : <Register />} />
        <Route path="/select-avatar" element={isAuth ? <SelectAvatar /> : <Navigate to="/" />} />
        <Route path="/home" element={isAuth ? <Home /> : <Navigate to="/" />} />
        <Route path="/journal" element={isAuth ? <JournalDashboard /> : <Navigate to="/" />} />
        <Route path="/new-journal" element={isAuth ? <JournalEntry /> : <Navigate to="/" />} />
        <Route path="/journal/:id" element={isAuth ? <JournalDetail /> : <Navigate to="/" />} />
        <Route path="/mood" element={isAuth ? <MoodTracker /> : <Navigate to="/" />} />
        <Route path="/mood-history" element={isAuth ? <MoodHistory /> : <Navigate to="/" />} />
        <Route path="/chat" element={isAuth ? <Chat /> : <Navigate to="/" />} />  {/* Chat Route */}
        <Route path="/sos" element={isAuth ? <SOS /> : <Navigate to="/" />} />
        <Route path="/helplines" element={isAuth ? <Helplines /> : <Navigate to="/" />} />
        
      </Routes>
    </Router>
  );
}

export default App;
