import { useState, useEffect } from 'react';
import axios from 'axios';

const Chat = () => {
  const [messages, setMessages] = useState<any[]>([]);  // Chat messages
  const [input, setInput] = useState('');  // User input text
  const [isThinking, setIsThinking] = useState(false);  // Bot thinking state
  const [error, setError] = useState('');  // Store errors

  const apiKey = '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'; // Replace with your actual API key

  // Define the system message to guide the bot (keep it minimal)
  const systemMessage = {
    role: 'system',
    content: 'You are a friendly virtual companion dedicated to providing mental health support.You recognize emotions, offer personalized coping strategies, and provide educational content.Ensure conversations are engaging, comforting, empathetic, and flow naturally.Avoid overly formal language and instead act like a human friend, giving supportive, concise,yet detailed responses based on the user\'s needs. Prioritize mental health and well-being in every conversation.',
  };

  useEffect(() => {
    setMessages([]); // Start chat with an empty message list
  }, []);

  // Prepare the message array to be sent to the API
  /*
  const getFilteredMessages = () => {
    return [{ role: 'user', content: input }];
  };
  */

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { role: 'user', content: input };
    const newMessages = [...messages, userMessage];

    setMessages(newMessages);  // Update the message list
    setInput('');  // Clear input
    setIsThinking(true);  // Show thinking state

    try {
      // Make a request to the OpenAI API
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4-turbo',
          messages: [systemMessage, { role: 'user', content: input }],  // Only send user input and system message
          max_tokens: 150,
          temperature: 1.3,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,  // Proper authorization
          },
        }
      );

      const aiResponse = response.data.choices[0].message.content;

      // Add the bot's response to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'bot', content: aiResponse },
      ]);
    } catch (err: any) {
      // Catch and display errors
      setError(`Error: ${err.response?.status || 'Unknown'} - ${err.message}`);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'bot', content: 'Error: Unable to get a response.' },
      ]);
    } finally {
      setIsThinking(false);  // Stop thinking state
    }
  };

  // Render chat messages
  const renderMessages = () => {
    return messages.map((message, index) => {
      const isUser = message.role === 'user';
      return (
        <div key={index} className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
          <div className={`p-4 rounded-xl max-w-xs shadow-lg ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>
            {message.content}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col h-screen bg-cover bg-center" style={{ backgroundImage: 'url(/assets/chat_background.png)' }}>
      <div className="flex-grow p-6 overflow-auto">
        {renderMessages()}
        {isThinking && (
          <div className="flex justify-start">
            <div className="p-4 rounded-xl max-w-xs bg-gray-300 text-black shadow-lg">
              Thinking...
            </div>
          </div>
        )}
        {error && <div className="text-red-500">{error}</div>} {/* Display error */}
      </div>
      <div className="p-4 bg-white flex">
        <input
          type="text"
          className="flex-grow p-3 border border-gray-400 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button onClick={handleSendMessage} className="bg-blue-500 text-white p-3 rounded-r-lg hover:bg-blue-600">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
