// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Paste your Firebase config here from the Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyAcCNd7y05PdFMQ_Cu5CWO7r4WY9gw6yRI",
    authDomain: "mentalhealthwebapp-a0d16.firebaseapp.com",
    projectId: "mentalhealthwebapp-a0d16",
    storageBucket: "mentalhealthwebapp-a0d16.appspot.com",
    messagingSenderId: "483149769934",
    appId: "1:483149769934:web:e16fc900c0204c6159349f",
    measurementId: "G-2LDVGPZCSK"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword };
