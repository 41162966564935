// src/components/JournalEntry.tsx
import { useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc, collection, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

// A few random prompts for the user
const prompts = [
  "What was the best part of your day?",
  "What are you grateful for?",
  "Describe a challenge you overcame today.",
  "What made you smile today?",
    "What are three things you're grateful for today?",
    "Describe a moment today when you felt proud of yourself.",
    "What is one challenge you faced today? How did you overcome it?",
    "List five things that made you smile today.",
    "Write about a time you helped someone and how it made you feel.",
    "What is something new you learned about yourself this week?",
    "Reflect on your happiest memory from this month.",
    "What are you looking forward to tomorrow?",
    "Describe a goal you achieved today, no matter how small.",
    "What is one thing you would like to improve about yourself?",
    "Write about a person who inspires you and why.",
    "How have you changed in the past year?",
    "What was the most peaceful moment of your day?",
    "Describe a recent dream and how it made you feel.",
    "What are three things you did well today?",
    "Write about a book or article that changed your perspective.",
    "What is a fear you faced recently, and how did you feel afterwards?",
    "Describe a place where you feel most at peace.",
    "What is a compliment you received that made you feel good?",
    "How did you express kindness today?",
    "What is a recent success and the steps you took to achieve it?",
    "Reflect on a moment today when you felt content.",
    "What is a song that uplifts you and why?",
    "Write about a mistake and the lesson you learned.",
    "What is something you're looking forward to achieving?",
    "Describe a moment when you felt connected to nature.",
    "What is a habit you're proud of breaking or forming?",
    "How do you deal with stress, and what can you do better?",
    "What is a recent act of kindness you witnessed?",
    "Write about a time you forgave someone. How did it make you feel?",
    "What are your thoughts on forgiveness?",
    "Describe a project or hobby you're currently working on.",
    "What is something that always brings you joy?",
    "Reflect on a strong emotion you felt today and why.",
    "What are three qualities you admire in others?",
    "Write about a difficult decision you made recently.",
    "How do you recharge after a stressful day?",
    "What is a piece of advice you would give your younger self?",
    "Describe a moment when you felt truly listened to.",
    "What are some ways you can show self-compassion?",
    "Write about a tradition that is important to you.",
    "What is a recent realization you had?",
    "How do you express creativity in your life?",
    "What is a personal strength you're proud of?",
    "Describe a small act of bravery you showed recently.",
    "What does happiness mean to you?",
    "Write about a time you overcame self-doubt.",
    "What are three things that worry you, and how can you address them?",
    "How do you define success in your life?",
    "What is a memory that makes you feel warm and fuzzy?",
    "Reflect on a compliment you wish to give someone.",
    "What are your hopes for the next month?",
    "Write about a moment when you felt at odds with yourself and how you resolved it.",
    "How do you practice mindfulness in your daily life?",
    "What is a lesson you wish to carry forward from today?",
    "Describe a recent experience where you felt intense joy.",
    "What are your thoughts on self-improvement?",
    "How do you stay motivated when things get tough?",
    "Write about a moment of unexpected kindness you experienced.",
    "What are the things that make you feel alive?",
    "Describe your ideal day from start to finish.",
    "What are your thoughts on love and compassion?",
    "How do you balance your personal and professional life?",
    "What is something you're struggling with, and how can you seek help?",
    "Write about an experience that made you see the world differently.",
    "What does self-care mean to you?",
    "Describe a time you took a risk and the outcome.",
    "What are your goals for the next year?",
    "How do you deal with change?",
    "What is something beautiful you saw today?",
    "Write about a time you felt intense gratitude.",
    "Who are you grateful for and why?",
    "What's a small victory you had today?",
    "Write about a moment you wish you could relive.",
    "How did you make someone smile today?",
    "What's something you're looking forward to this week?",
    "Describe your perfect weekend.",
    "What's a new skill you'd like to learn and why?",
    "Reflect on the best advice you've ever received.",
    "What's a recent obstacle and how did you navigate it?",
    "Write about a movie that moved you emotionally.",
    "What's one thing you did today to improve your mental health?",
    "Describe a moment when you felt incredibly brave.",
    "What's a tradition you want to start?",
    "Write about your favorite way to relax.",
    "What's something you did today that you're proud of?",
    "Describe a recent act of kindness you experienced.",
    "What's a book that has significantly influenced you?",
    "Reflect on a moment when you felt genuine joy.",
    "What's one goal you accomplished this month?",
    "Write about a place that feels like home to you.",
    "What's a challenge you're currently facing?",
    "Describe a moment when you felt at peace with yourself.",
    "What are three small things that brought you joy today?",
    "Write about a song that speaks to you and why.",
    "What's something you learned about a friend recently?",
    "Describe your ideal self. What steps can you take to get there?",
    "What's an experience that made you stronger?",
    "Reflect on a moment of clarity you had recently.",
    "What's a fear you've overcome?",
    "Write about a moment when you felt proud of a loved one.",
    "What's something you're grateful for in your environment?",
    "Describe a habit you want to develop.",
    "What's a moment today that you would like to remember?",
    "Write about a piece of art that changed your perspective.",
    "What's a recent personal revelation you've had?",
    "Describe a conversation that changed your outlook.",
    "What's something you forgive yourself for?",
    "Write about a time you chose happiness over correctness.",
    "What's an area of your life you'd like to simplify?",
    "Describe your feelings about the changing seasons.",
    "What's a lesson you learned the hard way?",
    "Write about a moment when you felt deeply connected to nature.",
    "What's a personal myth you've let go of?",
    "Describe a recent experience of feeling lost and how you found your way.",
    "What's a question you've been pondering lately?",
    "Write about a time you listened to your intuition.",
    "What's an act of self-love you practiced today?",
    "Describe your journey with self-esteem.",
    "What's a boundary you set recently, and how has it impacted you?",
    "Write about a moment you chose to be vulnerable.",
    "What's a passion project you'd like to start?",
    "Describe a time you felt overwhelmed by emotion.",
    "What's a belief you hold about yourself that you'd like to change?",
    "Write about a moment of unexpected beauty in your day.",
    "What's a word that describes your current state of mind?",
    "Describe a goal that scares you.",
    "What's a comforting thought that helps you through tough times?",
    "Write about a moment you felt truly free.",
    "What's an assumption you had that was proven wrong?",
    "Describe a time you made a difficult choice for your well-being.",
    "What's something you'd like to let go of?",
    "Write about a moment you witnessed courage in someone else.",
    "What's a piece of wisdom you'd like to pass on?",
    "Describe a simple pleasure that you cherish.",
    "What's a recent discovery you made about yourself?",
    "Write about a time you forgave someone.",
    "What's a personal value that's important to you?",
    "Describe a moment when you felt misunderstood.",
    "What's something you did today that felt like an act of self-care?",
    "Write about a time you felt conflicted and how you resolved it.",
    "What's a dream you had recently and its possible meaning?",
    "Describe a childhood memory that brings you joy.",
    "What's a quality you admire in someone close to you?",
    "Write about a time you stood up for what you believe in.",
    "What's a recent moment of laughter you shared with someone?",
    "Describe an experience that left you feeling grateful for your life.",
    "Describe an experience that left you feeling grateful for your life.",
    "What's a fear you'd like to confront?",
    "Write about a time you experienced growth through discomfort.",
    "What's an act of kindness you can perform tomorrow?",
    "Describe a moment when you felt truly heard.",
    "What's a personal challenge you've set for yourself?",
    "Write about a time you had to be strong for someone else.",
    "What's something that's been weighing on your mind?",
    "Describe a moment when you chose to see the good in a difficult situation.",
    "What's a positive change you've noticed in yourself?",
    "Write about a time you felt connected to something greater than yourself.",
    "What's a question you wish someone would ask you?",
    "Describe a time you felt rejuvenated by nature.",
    "What's a lesson you learned from a failure?",
    "Write about a time you felt genuinely happy for someone else.",
    "What's a personal victory you've had recently?",
    "Describe a moment when you felt proud to be you.",
    "What's a creative outlet that brings you joy?",
    "Write about a time you made a meaningful connection with a stranger.",
    "What's something you're curious about right now?",
    "Describe a time you were pleasantly surprised by an outcome.",
    "What's a goal you have for your personal growth?",
    "Write about a time you helped make someone's day better.",
    "What's a small act of kindness you observed recently?",
    "Describe a moment you felt in tune with your emotions.",
    "What's a change you'd like to see in your life?",
    "Write about a time you felt empowered to make a difference.",
    "What's a memory that makes you smile every time?",
    "Describe a moment when you felt challenged in a good way.",
    "What's something you did today that aligned with your values?",
    "Write about a time you prioritized your mental health.",
    "What's a compliment you received that you didn't believe at first?",
    "Describe a moment when you felt you made a positive impact.",
    "What's a recent insight you had about your life's direction?",
    "Write about a time you chose joy over despair.",
    "What's an aspect of your personality you're proud of?",
    "Describe a time you felt a strong sense of belonging.",
    "What's something you wish more people understood about you?",
    "Write about a moment you experienced a profound sense of peace.",
    "What's a recent challenge you turned into an opportunity?",
    "Describe a time you took care of someone.",
    "What's a part of your daily routine that you love?",
    "Write about a time you felt gratitude for something unexpected.",
    "What's a habit you've developed that has improved your life?",
    "Describe a moment you chose to be optimistic.",
    "What's something you learned from a difficult time?",
    "Write about a time you celebrated a personal achievement.",
    "What's a piece of advice you've received that you're grateful for?",
    "Describe a moment when you felt supported by a community.",
    "What's a fear you'd like to overcome this year?",
    "Write about a time you felt inner strength you didn't know you had.",
    "What's a recent moment of self-discovery?",
    "Describe a time you made a positive change in your life.",
    "What's something you did recently that made you feel proud?",
    "Write about a time you found beauty in a difficult situation.",
    "What's a goal you're working towards right now?",
    "Describe a moment when you felt a sense of achievement.",
    "What's a way you've grown that you never expected?",
    "Write about a time you felt truly content with what you have.",
    "What's a personal trait you've come to appreciate about yourself?",
    "Describe a moment when you felt close to someone.",
    "What's a lesson you're learning right now?",
    "Write about a time you chose to forgive.",
    "What's something you're thankful for in your work or studies?",
    "Describe a moment when you felt courage in the face of fear.",
    "What's a recent experience that taught you patience?",
    "Write about a time you felt a deep connection with art or music.",
    "What's something that's brought you comfort recently?",
    "Describe a moment when you were able to overcome self-doubt.",
    "What's a way you've helped someone recently?",
    "Write about a time you took a step towards a dream.",
    "What's a small joy you experienced today?",
    "Describe a moment when you felt proud of your resilience.",
    "What's a passion you want to explore more?",
    "Write about a time you found clarity in a confusing situation.",
    "What's something you're learning to accept about yourself?"
];

const JournalEntry = () => {
    const [prompt, setPrompt] = useState('');
    const [journalText, setJournalText] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    // Handle saving the journal entry to Firestore
    const handleSaveJournal = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('You need to be logged in to create a journal entry.');
        return;
      }
  
      if (!journalText) {
        setError('Your journal entry cannot be empty.');
        return;
      }
  
      const journalRef = doc(collection(db, 'users', user.uid, 'journals'));
      await setDoc(journalRef, {
        prompt: prompt || 'Free Write',
        journalText,
        date: Timestamp.now(),
      });
  
      navigate('/journal');
    };
  
    // Handle generating a random prompt
    const generateRandomPrompt = () => {
      const randomIndex = Math.floor(Math.random() * prompts.length);
      setPrompt(prompts[randomIndex]);
    };
  
    // Handle resetting to no prompt
    const resetPrompt = () => {
      setPrompt('');  // Clear the prompt, allowing free writing
    };
  
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-pastel-light">
        <h2 className="text-3xl font-bold text-gray-700 mb-4">New Journal Entry</h2>
        
        <button
          onClick={generateRandomPrompt}
          className="bg-blue-400 text-white py-2 px-4 rounded-lg mb-4 hover:bg-blue-500 transition duration-200"
        >
          Get Random Prompt
        </button>
  
        {prompt && (
          <div className="flex flex-col items-center mb-4">
            <p className="italic text-gray-600 mb-2">{prompt}</p>
            <button
              onClick={resetPrompt}
              className="text-blue-400 underline hover:text-blue-600"
            >
              Write Without a Prompt
            </button>
          </div>
        )}
  
        <textarea
          rows={6}
          placeholder="Start writing your thoughts..."
          className="w-full max-w-md p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 mb-4"
          value={journalText}
          onChange={(e) => setJournalText(e.target.value)}
        />
        
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        
        <button
          onClick={handleSaveJournal}
          className="bg-green-400 text-white py-2 px-4 rounded-lg hover:bg-green-500 transition duration-200"
        >
          Save Journal
        </button>
      </div>
    );
  };
  
  export default JournalEntry;